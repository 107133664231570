<template>
  <div class="accounts-frame not-minimal">
    <Drawer
      drawerClass="accounts-frame__drawer"
      :drawerBg="drawerBg"
      :drawerStyle="drawerStyle"
      :middleStyle="middleStyle"
      :showDrawer="showAccountsFrame"
      :closeDrawer="closeAccountsFrame">
      <div class="drawer__content" v-if="updatingAccountsFrame">
        <div class="drawer__empty">
          <div class="drawer__spinner-large">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
      <shopping-details v-if="mode === 'details'"
        :changeMode="changeMode"
        :closeDrawer="closeAccountsFrame"
      />
      <AddressSelector v-if="mode === 'accounts'"
        :changeMode="changeMode"
        :closeDrawer="closeAccountsFrame"
        :heading="`Select account`"
        :inputLabel="`Search for account`"
        :listMode="`account`"
        :mode="mode"
        :placeholder="`Account number, account name`"
        :selectText="`Select and continue to address`"
        :showAfterMessage="false"
        :showOneTime="false"
        :showMapMarker="true"
        :stringForFilter="accountStringForFilter"
      />
      <AddressSelector v-if="mode === 'addresses'"
        :changeMode="changeMode"
        :closeDrawer="closeAccountsFrame"
        :filterFunc="addressFilterFunc"
        :heading="`Select address`"
        :inputLabel="`Search addresses`"
        :listMode="`address`"
        :mode="mode"
        :selectText="`Select address`"
        :showAfterMessage="true"
        :showOneTime="true"
        :showMapMarker="false"
        :stringForFilter="addressStringForFilter"
      />
      <OneTimeAddress v-if="mode === 'onetime'"
        :changeMode="changeMode"
        :closeDrawer="closeAccountsFrame"
        :theHeading="oneHeading"
      />
    </Drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import axios from 'axios';
import { doAdobeEvents } from '@/helpers/adobe';
import Drawer from '@/components/common/Drawer.vue';
import ShoppingDetails from '@/components/accountsframe/ShoppingDetails.vue';
import AddressSelector from '@/components/accountsframe/AddressSelector.vue';
import OneTimeAddress from '@/components/accountsframe/OneTimeAddress.vue';

export default {
  name: 'AccountsFrame',
  components: {
    AddressSelector,
    Drawer,
    OneTimeAddress,
    ShoppingDetails,
  },
  props: {
    dboard: {
      type: Object,
      required: true,
    },
  },
  data: function data() {
    return {
      showAccountsFrame: false,
      updatingAccountsFrame: false,
      isGeneric: this.dboard.isgen,
      isAnonymous: this.dboard.isanon,
      cartItems: [],
      cartItemsById: {}, // hash of cart items by cartid, can be used to lookup items
      drawerStyle: {},
      middleStyle: {},
      drawerWidth: 575,
      drawerBg: false,
      mode: 'details',
    };
  },
  computed: {
    ...mapState('accountsframe', [
      'accounts',
      'accountsStatus',
      'current_address',
      'selected_address'
    ]),
    ...mapGetters('accountsframe', [
      'user_attrs'
    ]),
    hasItems() {
      return this.cartItems.length > 0;
    },
    loggedOut() {
      return this.isGeneric && this.isAnonymous;
    },
    oneHeading() {
      // enum('0','1','default','forced','auspost','forced_hd')
      if (this.user_attrs) {
        if (this.user_attrs.allow_changedelivery === 'forced') {
          return 'One Time Address';
        }
        if (this.user_attrs.allow_changedelivery === 'auspost') {
          return 'Home Delivery';
        }
        if (this.user_attrs.allow_changedelivery === 'forced_hd') {
          return 'Home Delivery';
        }
      }

      if (window.nxDatalayer?.global?.channel === 'retail') {
        return 'New Address';
      }

      return 'One Time Address';
    }
  },
  methods: {
    changeMode(newmode) {
      this.mode = newmode;
    },
    openAccountsFrame(evt) {
      // Stop original link click
      if (evt) evt.preventDefault();

      // Display and slide-in drawer
      this.showAccountsFrame = true;
      this.slideIn();

      // Hide page elements
      this.displayPageElements(false);

      doAdobeEvents({ event160: undefined }, {}, 'Opened accounts frame');
    },
    closeAccountsFrame(evt) {
      // Stop original link click
      evt.preventDefault();

      // Fade drawer background and slide-out drawer
      this.drawerBg = false;
      this.drawerStyle = {
        transition: 'margin 0.5s',
        width: `${this.drawerWidth}px`,
        'margin-right': `-${this.drawerWidth}px`,
      };

      // Allow drawer to slide-out before removal
      setTimeout(() => {
        this.showAccountsFrame = false;

        // Display page elements
        this.displayPageElements(true);
      }, 500);
    },
    displayPageElements(showElems) {
      // Find page elements
      const btto = document.getElementById('btto');
      const clogo = document.querySelector('.masthead-middle');
      // Toggle display
      if (showElems) {
        if (clogo) { clogo.style.zIndex = '700'; }
        window.nx.chat.showChat(true);
        if (btto) { btto.classList.remove('hide-for-now'); }
        document.body.classList.remove('no_scroll_minicart');
      } else {
        if (clogo) { clogo.style.zIndex = '0'; }
        window.nx.chat.showChat(false);
        if (btto) { btto.classList.add('hide-for-now'); }
        document.body.classList.add('no_scroll_minicart');
      }
    },
    addressFilterFunc(filterString, searchString, all_items) {
      const st = searchString.toUpperCase();
      const rx = new RegExp(`\\b${st}`);

      return filterString(all_items).filter((el) => {
        return el.composite.match(rx);
      });
    },
    slideIn() {
      setTimeout(() => { this.drawerBg = true; }, 100);
      this.drawerStyle = { 'margin-right': `-${this.drawerWidth}px` };
      this.middleStyle = { width: `${this.drawerWidth}px` };
      setTimeout(() => {
        this.drawerStyle = {
          transition: 'margin 0.5s',
          width: `${this.drawerWidth}px`,
          'margin-right': 0,
        };
      }, 100);
    },
    accountStringForFilter(items) {
      return items.map((el) => {
        el.composite = `${el.ccode} ${el.acc_desc}`;
        el.composite = el.composite.toUpperCase();
        return el;
      }, []);
    },
    addressStringForFilter(items) {
      return items.map((el) => {
        el.composite = [el.address2, el.address1, el.city, el.state, el.postcode]
          .filter((elem) => typeof elem !== 'undefined' && elem !== '')
          .join(' ');
        el.composite = el.composite.toUpperCase();
        return el;
      }, []);
    },
  },
  mounted() {
    window.nx.addToCart.openAccountsFrame = this.openAccountsFrame;
  },
};
</script>
