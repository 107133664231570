/*
use mapState Helper if state is directly used instead of getters
getters is needed only if we need modified/filtered data from state
Eg: invoiceByDocumentNumber
*/

export function selected_acc_desc(state) {
  return state.selected_acc_desc;
}

export function current_acc_desc(state) {
  return state.current_acc_desc;
}

export function all_accounts(state) {
  return state.accounts;
}

export function ccode(state) {
  return state.ccode;
}

export function current_ccode(state) {
  return state.current_ccode;
}

export function user_attrs(state) {
  return state.user;
}

export function current_addr(state) {
  return state.current_address;
}

export function selected_addr(state) {
  return state.selected_address;
}

export function unique_accounts(state) {
  return [
    ...new Map(
      state.accounts
        .filter(Boolean)
        .map((addr) => [addr.ccode, addr])
    ).values()
  ];
}

export function filtered_accounts(state) {
  return state.accounts
    .filter((addr) => addr.ccode === state.current_ccode);
}
