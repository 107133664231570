<template>
  <div class="filter-list">
    <div>
      <label class="filter-list__label-input" :id="`${baseId}__label`"
        :for="inputId">{{ inputLabel }}</label>
      <div class="filter-list__container-input">
        <input :id="inputId" role="combobox" class="filter-list__input-filter"
          aria-autocomplete="list" aria-haspopup="listbox" aria-expanded="true"
          :aria-controls="listID"
          :placeholder="placeholder"
          :value="searchTarget"
          v-on:input="updateSearch"
          autofocus="true"
        />
        <div class="fa fa-search"></div>
      </div>
    </div>
    <ul role="listbox" :id="listID" class="filter-list__list"
      :aria-labelledby="`${baseId}__label`">
      <li v-for="item, index in filteredItems"
        :key="item.actualIndex"
        role="option"
        :aria-selected="isSelected(item)">
          <slot
            name="item"
            :item="item"
            :navSelected="navIndex === index"
            :index="index"></slot>
      </li>
      <slot name="no-results" v-if="filteredItems.length === 0"></slot>
      <slot name="end-results" v-if="filteredItems.length !== 0"></slot>
    </ul>
  </div>
</template>
<script>

import { doAdobeEvents } from '@/helpers/adobe';

export default {
  name: 'NxFilterList',
  components: {
  },
  data() {
    return {
      searchTarget: '',
    };
  },
  props: {
    baseId: {
      type: String,
      default: 'alist'
    },
    items: {
      type: Array,
      default(rawProps) { return []; },
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    filterFunc: {
      type: Function
    },
    listID: {
      type: String,
      default: 'the-list'
    },
    stringForFilter: {
      type: Function,
      default: () => {},
    },
    isSelected: {
      type: Function
    },
    inputId: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    selectItem: {
      type: Function
    }
  },
  computed: {
    filteredItems() {
      if (this.searchTarget === '') return this.items;

      if (this.filterFunc && (typeof this.filterFunc === 'function')) {
        return this.filterFunc(this.stringForFilter, this.searchTarget, this.items);
      }

      const st = this.searchTarget.toUpperCase();
      const rx = new RegExp(`\\b${st}|${st}\\b`);

      return this.stringForFilter(this.items).filter((el) => {
        return el.composite.match(rx);
      });
    },
  },
  methods: {
    updateSearch(ev) {
      this.searchTarget = ev.target.value;
    },
  },
};
</script>
