<template>
  <aside class="drawer__content" aria-labelledby="address-filter__heading">
    <return-details :changeMode="changeMode" :allowReturn="allowReturn" />
    <header class="drawer__header">
      <div class="leftright">
        <h2 class="header2-24" id="address-filter__heading">{{ heading }}</h2>
        <div v-if="showButton">
          <button class="wc-button wc-button--secondary" @click="changeMode('onetime')">
            <span class="label">{{ buttonText }}</span>
          </button>
        </div>
      </div>
    </header>
    <div class="drawer__active address-selector">
      <section class="drawer__active-items">
        <nx-filter-list
          :inputId="`account_filter`"
          :inputLabel="inputLabel"
          :items="ccodeAddresses"
          :canEdit="canEdit"
          :filterFunc="filterFunc"
          :isSelected="isSelected"
          :selectItem="selectAddress"
          :stringForFilter="stringForFilter">
          <template v-slot:item="slotProps">
            <one-address-item :item="slotProps.item"
              :getChooseButton="getChooseButton"
              :index="slotProps.index"
              :listMode="listMode"
              :showMapMarker="false"
            />
          </template>
        </nx-filter-list>
        <nx-spinner v-if="showSpinner" />
        <p class="filter-list__message-after" v-else-if="showAfterMessage">
          Can't find your address?
          <a href="/main-support-contactus">Contact us</a>
          to request a new address for this account
        </p>
      </section>
      <footer class="drawer__active-footer">
        <div class="address-filter__message-container" v-if="choseNewOne">
          <message message-type="warn">
            <h4 class="accounts-frame__headinglabel"
              >Your address will be changed</h4>
            <div>Delivery times and costs may also be updated.</div>
          </message>
        </div>
        <div class="address-filter__buttons-container">
          <button class="wc wc-button wc-button--secondary"
            @click="changeMode('details')">
            <span class="label">Cancel</span>
          </button>
          <button class="wc wc-button wc-button--primary"
            ref="addressSelectorButtonSelect" @click="chooseAddress">
            <span class="label updating" v-if="showUpdatingSpinner">Updating
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </span>
            <span class="label">{{ selectText }}</span>
          </button>
        </div>
      </footer>
    </div>
  </aside>
</template>

<script>
// Stream is auf - Address Up Front
import axios from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';
import NxFilterList from '@/components/common/NxFilterList.vue';
import NxSpinner from '@/components/common/NxSpinner.vue';
import { makeToast } from '@/helpers/helpers';
import { doAdobeEvents } from '@/helpers/adobe';
import OneAddressItem from './OneAddressItem.vue';
import ReturnDetails from './ReturnDetails.vue';
import message from '../common/Message/Message.vue';

export default {
  name: 'AddressSelector',
  components: {
    message,
    NxFilterList,
    NxSpinner,
    OneAddressItem,
    ReturnDetails,
  },
  props: {
    args: {
      type: String
    },
    changeMode: {
      type: Function
    },
    closeDrawer: {
      type: Function
    },
    filterFunc: {
      type: Function
    },
    heading: {
      type: String
    },
    inputLabel: {
      type: String
    },
    listMode: {
      type: String
    },
    mode: {
      type: String
    },
    placeholder: {
      type: String
    },
    selectText: {
      type: String
    },
    showAfterMessage: {
      type: Boolean
    },
    showMapMarker: {
      type: Boolean
    },
    showOneTime: {
      type: Boolean
    },
    stringForFilter: {
      type: Function
    },
  },
  data: function data() {
    return {
      items: [],
      fieldId: 'addr',
      showSpinner: false,
      showUpdatingSpinner: false,
      showFailure: false,
      sap_address_id: null,
      tmp_key: null
    };
  },
  computed: {
    ...mapGetters('accountsframe', [
      'user_attrs',
      'selected_acc_desc',
      'current_acc_desc',
      'ccode',
      'current_ccode',
      'current_addr',
      'filtered_accounts',
      'selected_addr',
      'unique_accounts',
    ]),
    ...mapState('accountsframe', [
      'accounts',
      'chose_one',
    ]),
    allowReturn() {
      return !!this.selected_addr.postcode;
    },
    buttonText() {
      if (this.user_attrs.allow_changedelivery === 'forced') {
        return 'One Time Address';
      }
      if (this.user_attrs.allow_changedelivery === 'auspost') {
        return 'Home Delivery';
      }
      if (this.user_attrs.allow_changedelivery === 'forced_hd') {
        return 'Home Delivery';
      }

      if (this.isRetail) {
        return 'New Address';
      }

      return 'One Time Address';
    },
    ccodeAddresses() {
      if (this.accounts.length === 0) return [];
      if (this.mode === 'accounts') {
        return this.unique_accounts;
      }

      return this.filtered_accounts;
    },
    canEdit() {
      return true;
    },
    choseNewOne() {
      if (this.mode === 'accounts') {
        return false;
      }

      return this.chose_one;
    },
    isRetail() {
      return window.nxDatalayer?.global?.channel === 'retail';
    },
    showButton() {
      return (this.showOneTime &&
        ((this?.user_attrs?.allow_changedelivery !== '0') ||
        (window.nxDatalayer?.global?.channel === 'retail')));
    }
  },
  methods: {
    ...mapActions('accountsframe', [
      'setCCode',
      'updateAccDesc',
      'updateCurrentAccDesc',
      'updateCurrentCCode',
      'updateCurrrentAddress',
      'updateSelectedAddress'
    ]),
    getChooseButton() {
      return this.$refs.addressSelectorButtonSelect;
    },
    selectAddress(address) {
      this.tmp_key = address.key;
    },
    scrollSelectedAddressBySapId() {
      const allAddresses = this.items;
      const addressEdited = allAddresses.find((obj) => obj.sap_address_id === this.sap_address_id);

      // Scroll to Edited Address
      const scrollDiv = document.getElementById('addressListScrollDiv');
      const scrollDivOffset = scrollDiv.offsetTop;
      const targetDiv = document.querySelector(`div[for="${addressEdited.actualIndex}"]`);
      const targetDivOffset = targetDiv.offsetTop;
      // 100 offset to move little down.
      scrollDiv.scrollTop = targetDivOffset - scrollDivOffset - 100;
    },
    isSelected(the_item) {
      if (this.mode === 'accounts') {
        return the_item.ccode === this.ccode;
      }

      return (((the_item.sap_address_id && this.selected_addr.sap_address_id) &&
        (the_item.sap_address_id === this.selected_addr.sap_address_id)) ||
        ((the_item.addresshash && this.selected_addr.addresshash) &&
        (the_item.addresshash === this.selected_addr.addresshash)));
    },
    chooseAddress() {
      if (this.mode === 'accounts') {
        this.changeCcode(this.ccode, this.selected_acc_desc);
      } else {
        this.saveAddressToSAP(this.selected_addr);
      }
    },
    changeCcode(newccode, newdesc) {
      this.showUpdatingSpinner = true;
      const url = '/main-ajax-changeccode';

      axios.post(url, { ccode: newccode },
        { headers: { 'X-Post-ID': nxDatalayer.global.post_id }
        })
        .then((resp) => {
          this.showUpdatingSpinner = false;

          const empty_address = { };

          ['addresshash', 'address1', 'address2', 'city', 'state', 'postcode', 'country',
            'description', 'sap_address_id'].forEach((key) => {
            empty_address[key] = '';
          });
          empty_address.ccode = newccode;
          empty_address.acc_desc = newdesc;

          this.setCCode(newccode);
          this.updateCurrrentAddress(empty_address);
          this.updateSelectedAddress(empty_address);
          this.updateCurrentCCode(newccode);
          this.updateCurrentAccDesc(newdesc);

          makeToast({
            text: 'Your account has been changed',
            duration: 9000,
          });

          this.changeMode('addresses');
        }).catch((err) => {
          makeToast({
            text: 'An unexpected error occurred, please try again.',
            type: 'error',
            duration: -1
          });
        });
    },
    saveAddressToSAP(newAddress, oldAddress) {
      this.showUpdatingSpinner = true;
      doAdobeEvents({ event134: undefined }, {}, 'Address update confirm');

      const endPoint = `/main-ajax-addressupdate?userid=${window.nxDatalayer.global.userid}`;
      // expects return of either
      // { update: 'ok', err_code: 0 }
      // { update: 'fail', err_code: 999 }
      axios
        .post(endPoint, {
          newAddress,
          oldAddress,
          userid: this.user_attrs.userid,
          post_id: window.nxDatalayer.global.post_id,
          update_order: 1,
        })
        .then((response) => {
          // refresh order and show toast if it worked
          // @ts-ignore
          if (response.data.update === 'ok') {
            // refresh the addresses
            // this.sap_address_id = oldAddress.sap_address_id;
            this.updateCurrrentAddress(newAddress);

            doAdobeEvents({ event135: undefined }, {}, 'Address update success');
            this.showUpdatingSpinner = false;

            makeToast({
              text: 'Your address has been changed',
              duration: 9000,
            });

            window.etdp.postcodeChanged(newAddress.postcode);
          } else {
            // prompt if fail
            this.showFailure = true;
            this.showUpdatingSpinner = false;
            doAdobeEvents({ event136: undefined }, {}, 'Address update failure');
          }
        })
        .catch((err) => {
          makeToast({
            text: 'An unexpected error occurred, please try again.',
            type: 'error',
            duration: -1
          });
        })
        .finally(() => {
          this.showUpdatingSpinner = false;
          window.location.reload(); // Reload page so postcode takes affect
        });
    },
  }
};
</script>
