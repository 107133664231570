import Vue from 'vue';
import Notifications from 'vue-notification';
import AccountsFrame from '../components/AccountsFrame.vue';
import store from '../store/accountsStore';

Vue.use(Notifications);

Vue.config.devtools = true;

// Import data from the Taco into the Vue object
const div_id = 'accs';
const data_args = JSON.parse(document.getElementById(div_id).getAttribute('data-args'));

const accounts_frame = new Vue({
  store,
  render: (h) => h(AccountsFrame, { props: { dboard: data_args } }),
}).$mount(`#${div_id}`);
