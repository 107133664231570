<template>
  <nav class="current-account">
    <h3 class="current-account__title"
      ><i :class="`fa ${display_obj.icon}`"></i>{{ display_obj.heading }}
    </h3>
    <div class="current-account__detail">
      <div v-if="loadingAccounts">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div v-else-if="showAccountInfo" class="current-account__details">
        {{ formattedAccount }}
      </div>
      <div v-else-if="showInfo" class="current-account__details">
        <h4 v-if="formattedAccount"
          class="accounts-frame__headinglabel"><i :class="iconClass"></i>{{ formattedAccount }}</h4>
        <div v-if="formattedAddress">{{ formattedAddress }}</div>
      </div>
      <div style="margin: auto 0" v-else>
        <i class="fa fa-triangle-exclamation"
          style="color: #efa500; margin-right: 8px;"></i>{{ display_obj.unselected }}
      </div>
      <button :class="`wc wc-button ${btnClass}`" :disabled="isDisabled"
        @click="nextScreen()">
        <span class="label">{{ selectMessage }}</span>
      </button>
    </div>
  </nav>
</template>

<script>

import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CurrentAccount',
  props: {
    changeMode: {
      type: Function
    },
    curr_address: {
      type: Object
    },
    display_obj: {
      type: Object
    },
    loadingAccounts: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('accountsframe', [
      'selected_acc_desc',
      'ccode',
      'current_ccode',
      'current_acc_desc',
      'user_attrs'
    ]),
    ...mapState('accountsframe', [
      'accounts',
    ]),
    btnClass() {
      if (this.display_obj.mode === 'accounts') {
        return 'wc-button--secondary';
      }

      if (this.showInfo) {
        return 'wc-button--secondary';
      }

      return 'wc-button--primary';
    },
    showAccountInfo() {
      if (this.display_obj.mode === 'accounts') {
        return !!this.user_attrs.acc_desc;
      }

      return false;
    },
    selectMessage() {
      if (this.showAccountInfo || this.showInfo) {
        const msg = this.display_obj.select_msg;
        return msg.replace(/Select/, 'Change');
      }
      return this.display_obj.select_msg;
    },
    showInfo() {
      return !!this.curr_address.address1;
    },
    isDisabled() {
      if (this.display_obj.mode === 'accounts') {
        if (this.accounts.length === 1) {
          return true;
        }

        // Check for multiple accounts/ccodes
        if (!this.accounts.some((acc, idx, arr) => {
          if (!idx) {
            return false;
          }
          return acc.ccode !== arr[idx - 1].ccode;
        })) {
          return true;
        }
      }

      return false;
    },
    formattedAddress() {
      if (this.display_obj.mode === 'accounts') {
        return '';
      }

      const address = this.curr_address;
      const {
        description,
        address1,
        address2,
        state,
        postcode,
        city,
        deliverynote,
        selected
      } = address;

      return [address2, address1, city, `${state} ${postcode}`]
        .filter((elem) => typeof elem !== 'undefined' && elem !== null && elem !== '')
        .join(', ');
    },
    formattedAccount() {
      if (this.display_obj.mode === 'accounts') {
        if (this.current_acc_desc) {
          return `${this.current_ccode} - ${this.current_acc_desc.toUpperCase()}`;
        }

        return (this.user_attrs.acc_desc || '');
      }

      if (this.curr_address.sap_address_id) {
        return this.curr_address.description
          ? this.curr_address.description.toUpperCase()
          : '';
      }

      if (this.user_attrs.allow_changedelivery === 'auspost') {
        return 'Home delivery';
      }

      return 'One time address';
    },
    iconClass() {
      if ((this.display_obj.mode === 'accounts') ||
        (this.curr_address.sap_address_id)) {
        return '';
      }

      if (this.user_attrs.allow_changedelivery === 'auspost') {
        return 'fa fa-home';
      }

      return 'fa fa-location-dot';
    }
  },
  methods: {
    nextScreen() {
      if ((this.display_obj.mode === 'addresses') &&
        ((/forced/).test(this.user_attrs.allow_changedelivery))) {
        return this.changeMode('onetime');
      }

      return this.changeMode(this.display_obj.mode);
    }
  }
};
</script>
