<template>
   <label :class="liClass"
     :id="`${fieldId}_li_${index}`"
     tabindex="0"
     v-on:keyup.enter="selectAccount(item, true)"
     @click.prevent="selectAccount(item, false)"
   >
     <div class="filter-list__radio-holder">
       <input type="radio" name="addrs" tabindex="-1"
         :id="`${fieldId}__item_${index}`"
         style="display: none"
       />
       <i :class="radioClass"></i>
     </div>
     <div class="filter-list__label-holder"
       :for="`${fieldId}__item_${index}`"
     >
       <div class="filter-list__item-label"
         v-if="getFormattedDesc">{{ getFormattedDesc }}</div>
       <i class="fa fa-map-marker" v-if="showMapMarker"></i>
       {{ getFormattedAddress }}
     </div>
   </label>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OneAddressItem',
  props: [
    'getChooseButton',
    'index',
    'item',
    'listMode',
    'showMapMarker',
  ],
  data: function data() {
    return {
      fieldId: 'addr',
    };
  },
  computed: {
    ...mapGetters('accountsframe', [
      'ccode',
      'current_addr',
      'selected_addr',
      'user_attrs'
    ]),
    getFormattedDesc() {
      if (this.listMode === 'account') {
        return `${this.item.ccode} - ${this.item.acc_desc}`;
      }

      return this.item.description;
    },
    getFormattedAddress() {
      if (this.listMode === 'account') {
        return '';
      }

      const {
        description,
        address1,
        address2,
        state,
        postcode,
        city,
        deliverynote,
        selected
      } = this.item;

      return [address2, address1, city, `${state} ${postcode}`]
        .filter((elem) => typeof elem !== 'undefined' && elem !== '')
        .join(', ');
    },
    isSelected() {
      if (this.listMode === 'account') {
        return this.item.ccode === this.ccode;
      }

      return (((this.item.sap_address_id && this.selected_addr.sap_address_id) &&
        (this.item.sap_address_id === this.selected_addr.sap_address_id)) ||
        ((this.item.addresshash && this.selected_addr.addresshash) &&
        (this.item.addresshash === this.selected_addr.addresshash)));
    },
    liClass() {
      if (this.isSelected) {
        return 'filter-list__item filter-list__item--selected';
      }

      return 'filter-list__item';
    },
    radioClass() {
      if (this.isSelected) {
        return 'fa fa-circle-dot';
      }
      return 'fa fa-circle-o';
    },
  },
  methods: {
    ...mapActions('accountsframe', [
      'setCCode',
      'updateAccDesc',
      'updateChoseOne',
      'updateSelectedAddress'
    ]),
    selectAccount(addr, jump) {
      if (this.listMode === 'account') {
        this.setCCode(addr.ccode);
        this.updateAccDesc(addr.acc_desc);
      } else {
        this.updateSelectedAddress(addr);

        if ((addr.sap_address_id !== this.current_addr.sap_address_id) &&
          (addr.addresshash !== this.current_addr.addresshash)) {
          this.updateChoseOne(true);
        } else {
          this.updateChoseOne(false);
        }
      }

      if (jump) {
        const btn = this.getChooseButton();
        btn.focus();
      }
    },
  }
};
</script>
