import Axios from 'axios';
import { NxData } from '@/helpers/NxData';

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    if (response.data.live_debug) {
      const vlive_debug_html = response.data.live_debug + document.getElementById('vlive_debug').innerHTML;
      document.getElementById('vlive_debug').innerHTML = vlive_debug_html;
    }
    return response;
  }
);

/*
****************************************************************
* Action: getAccounts
* Desc: This action will get the accounts from AJAX endpoint
****************************************************************
*/

export function getAccounts({ dispatch, commit, state }, payload) {
  const uri = `/main-ajax-addressupdate?userid=${window.nxDatalayer.global.userid}`;

  if (state.got_accounts) {
    return;
  }

  state.accountsStatus = 'loading';

  Axios.get(uri, {
    headers: { 'X-Post-ID': window.nxDatalayer.global.post_id }
  }).then((response) => {
    let got_current = false;

    commit('setAccounts', {
      ccode: window.nxDatalayer.global.ccode,
      rows: response.data.addresses,
      user: response.data.user
    });

    response.data.addresses.forEach((addr) => {
      if (addr.is_current) {
        got_current = true;
        commit('setCurrrentAddress', addr);
        commit('setSelectedAddress', addr);
      }

      if (addr.ccode === state.ccode) {
        state.acc_desc = addr.acc_desc;
        state.current_acc_desc = addr.acc_desc;
      }
    });

    if (!got_current && response.data.user.addr && response.data.user.addr.deliveryhash) {
      commit('setCurrrentAddress', response.data.user.addr);
    }

    commit('updateCurrentCCode', state.ccode);

    state.got_accounts = true;
    state.accountsStatus = 'loaded';
  }).catch((error) => {
    commit('setAccounts', { ccode: window.nxDatalayer.global.ccode, rows: [] });

    if (error.response && (/^4/).test(error.response.status)) {
      toastr.options.timeOut = 5000; // 5 seconds

      toastr.error('There has been a error. ' +
      'Please sign out and then sign in again',
      'System Error');
    }

    state.accountsStatus = 'loaded';
  });
}

export function setCCode({ commit }, payload) {
  commit('setCCode', payload);
}

export function updateCurrrentAddress({ commit }, payload) {
  commit('setCurrrentAddress', payload);
}

export function updateSelectedAddress({ commit }, payload) {
  commit('setSelectedAddress', payload);
}

export function updateOneTimeAddress({ commit }, payload) {
  commit('setOneTimeAddress', payload);
}

export function updateChoseOne({ commit }, payload) {
  commit('setChoseOne', payload);
}

export function updateCurrentCCode({ commit }, payload) {
  commit('setCurrentCCode', payload);
}

export function updateAccDesc({ commit }, payload) {
  commit('setAccDesc', payload);
}

export function updateCurrentAccDesc({ commit }, payload) {
  commit('setCurrentAccDesc', payload);
}
